<template>
  <component is="b-card">
    <b-modal
      ref="add-visit-modal"
      ok-only
      :ok-title="$t('Add')"
      centered
      size="lg"
      :title="$t('Add visit')"
      no-close-on-backdrop
      @ok="addVisit"
      @hidden="addVisitModalHidden"
    >
      <b-row>
        <b-col>
          <b-form-group :label="$t('Select client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              v-model="addVisitSelectedClient"
              label="name"
              input-id="client"
              @search="fetchClientOptions"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Select wording')" label-for="wording">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="wordings"
              v-model="addVisitSelectedWording"
              label="name"
              input-id="wording"
              @search="fetchWordingOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-card no-body class="mb-0">
      <b-row class="mb-2">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="openAddVisitModal"
          >
            {{ $t("Add visit") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('Select client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              v-model="selectedClient"
              label="name"
              input-id="client"
              @search="fetchClientOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="refVisitListTable"
        class="position-relative"
        :items="fetchVisits"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="!data.item.result"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            @click="validateVisitModal(data.item)"
          >
            <feather-icon icon="CheckIcon" size="16" />
          </b-button>

          <b-modal
            :ref="'validate-visit' + data.item.id + '-modal'"
            ok-only
            :ok-title="$t('Save')"
            centered
            @ok="validateAddVisit"
            @hidden="validateAddVisitHidden"
            :title="$t('Alert')"
            no-close-on-backdrop
          >
            <b-row>
              <b-col>
                <b-form-group :label="$t('Select wording')" label-for="wording">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="wordings"
                    v-model="addVisitResultSelectedWording"
                    label="name"
                    input-id="wording"
                    @search="fetchWordingOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalVisits"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import useVisitList from "./useVisitList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,

    vSelect,
    flatPickr,
  },
  data() {
    return {
      clients: [],
      wordings: [],
      visit: null,
      addVisitSelectedClient: null,
      addVisitSelectedWording: null,
      addVisitResultSelectedWording: null,
      wordingsType: null,
    };
  },
  async mounted() {
    try {
      const res = await Promise.all([instance.get("/parameters/clients/")]);

      this.clients = res[0].data.results;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    validateAddVisitHidden() {
      this.addVisitResultSelectedWording = null;
    },
    async validateAddVisit(bvEvent) {
      if (!this.addVisitResultSelectedWording) {
        bvEvent.preventDefault();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The Wording is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        await instance.put("/recoveries/visits-create/", {
          result: this.addVisitResultSelectedWording.id,
          id: this.visit.id,
        });

        this.refetchData();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async validateVisitModal(visit) {
      try {
        const res = await instance.get("/recoveries/wordings/", {
          params: { type: 2 },
        });
        this.wordings = res.data.results;

        this.wordingsType = 2;
        this.$refs["validate-visit" + visit.id + "-modal"].show();

        this.visit = visit;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    addVisitModalHidden() {
      this.addVisitSelectedClient = null;
      this.addVisitSelectedWording = null;
    },
    async openAddVisitModal() {
      try {
        const res = await instance.get("/recoveries/wordings/", {
          params: { type: 1 },
        });
        this.wordings = res.data.results;

        this.wordingsType = 1;
        this.$refs["add-visit-modal"].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async addVisit(bvEvent) {
      if (!this.addVisitSelectedClient) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The Client is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });

        return;
      }

      if (!this.addVisitSelectedWording) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The wording is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });

        return;
      }
      try {
        await instance.post("/recoveries/visits-create/", {
          client: this.addVisitSelectedClient.id,
          reason: this.addVisitSelectedWording.id,
        });

        this.refetchData();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clients = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
    fetchWordingOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/recoveries/wordings/", {
            params: { search, type: this.wordingsType },
          });
          this.wordings = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },
  setup() {
    const {
      fetchVisits,
      tableColumns,
      perPage,
      currentPage,
      totalVisits,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVisitListTable,
      refetchData,
      selectedClient,
      refValue,
      selectedPaymentMethod,
    } = useVisitList();

    return {
      fetchVisits,
      tableColumns,
      perPage,
      currentPage,
      totalVisits,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVisitListTable,
      refetchData,
      // Filter
      avatarText,
      selectedClient,
      refValue,
      selectedPaymentMethod,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
