import { ref, watch, computed } from "@vue/composition-api";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useToast } from "vue-toastification/composition";

export default function useVisitList() {
  // Use toast
  const { t } = useI18nUtils();
  const toast = useToast();

  const refVisitListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // { key: "id" },
    { key: "client.name", label: t("Client") },
    { key: "reason.name", label: t("Reason") },
    { key: "reason_date", label: t("Reason Date") },
    { key: "result.name", label: t("Result") },
    { key: "result_date", label: t("Result Date") },
    { key: "actions", label: t("actions") },
  ];
  const perPage = ref(10);
  const totalVisits = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const refValue = ref("");
  const selectedClient = ref(null);
  const selectedPaymentMethod = ref(null);
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refVisitListTable.value
      ? refVisitListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalVisits.value,
    };
  });

  const refetchData = () => {
    refVisitListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      selectedClient,
      refValue,
      selectedPaymentMethod,
    ],
    () => {
      refetchData();
    }
  );

  const fetchVisits = (ctx, callback) => {
    instance
      .get("/recoveries/visits/", {
        params: {
          page: currentPage.value,
          client: selectedClient.value?.id,
        },
      })
      .then((response) => {
        const { count, results } = response.data;
        totalVisits.value = count;
        callback(results);
      })
      .catch((err) => {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        toast({
          component: ToastificationContent,
          props: {
            title: t("Error"),
            text: error ?? t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchVisits,
    tableColumns,
    perPage,
    currentPage,
    totalVisits,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refVisitListTable,
    refetchData,
    t,
    selectedClient,
    refValue,
    selectedPaymentMethod,
  };
}
